.userlist {
  position: absolute;
  left: 8px;
  top: 8px;
  background-color: #ffffffcc;
  color: black;

  max-width: 20vw;
  list-style-type: none;
  overflow: auto;
  max-height: 97.5%;

  .user {
    &.no_data {
      color: #666666;
    }

    &.error {
      color: red;
    }

    &.loading {
      color: #333;
    }
  }

  .exportButton {
    display: block;
    padding: 4px;
    margin: 8px auto;
  }
}
