.selected-marker {
  background-color: rgb(35, 187, 35);
  border: 1px solid green;
  border-radius: 100px;
  color: white;
  width: 25px;
  height: 25px;
  line-height: 22px;
  font-weight: bold;
  text-align: center;
}
