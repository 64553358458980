.questions {
  height: 100%;
  overflow: scroll;
  padding: 16px;
}

.question {
  margin-bottom: 35px;

  .question_header {
    display: flex;

    label {
      flex: 1;
      font-size: 11pt;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input {
      width: 25px;
      height: 25px;
      margin: 8px;
    }
  }

  textarea {
    color: black;
    background-color: white;
    width: 100%;
    padding: 16px;
    font-size: 11pt;
    font-family: sans-serif;
    border: 1px solid #d5d5d5;
    border-radius: 16px;
  }
}

.area_buttons {
  display: flex;

  button {
    flex: 1;
    border: 0;
    color: white;
    border-radius: 7px;
    margin: 0 auto;

    padding: 16px;
    font-size: 20px;
    background-color: #0101bb;
  }
}
