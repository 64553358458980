.indicator_choice {
  background-color: #eee;
  cursor: pointer;
}

.buttons {
  display: flex;
}

.address {
  text-align: center;
  color: #919191;
  font-size: 8pt;
  margin-top: -4px;
  margin-bottom: 4px;
}

.observation_buttons {
  position: absolute;
  z-index: 1;
  bottom: 0;
  transform: translate(0.5rem, 50%);
  display: flex;
  gap: 0.5rem;
}

.action {
  border: 0;
  color: white;
  border-radius: 7px;
  flex: 1;

  float: right;
  margin: 8px;
  padding: 16px;
  font-size: 20px;

  &.save {
    background-color: #0101bb;
  }
  &.delete {
    background-color: #880000;
  }
}
