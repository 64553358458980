.indicator_list {
  height: 100%;
  overflow: scroll;
  h3 {
    margin-top: 8px;
    font-size: larger;
    margin-bottom: 2px;
  }

  p.description,
  h3 {
    padding-left: 4px;
  }

  p.description {
    font-size: x-small;
    color: #888;
    margin-left: 8px;
    margin-right: 8px;
  }

  .choices_list {
    margin-bottom: 32px;
  }
}
