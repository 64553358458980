.edit_entry {
  flex: 1;
  margin: 2px 8px 6px;
  position: relative;

  .date {
    position: absolute;
    left: 50%;
    transform: translate(-50%,50%);
    padding-left: 4px;
    padding-right: 4px;
    bottom: 100%;
    font-weight: 600;
    text-align: center;
    font-size: smaller;
    color: #9f9f9f;
    background-color: white;
  }

  input {
    display: none;
  }

  button {
    cursor: pointer;
  }

  .photo {
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 40px;
    height: 30px;
    background-color: transparent;
    border: 0;

    &.exists {
      width: 60px;
      height: 50px;
    }

    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  textarea {
    shape-outside: circle(50% at 0 0) margin-box;
    height: 100%;
    max-height: unset;
    color: unset;
    background-color: unset;
  }
}
