.login {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;

  img {
    width: 50%;
    margin: 20px auto;
  }

  input {
    font-size: 30pt;
    text-align: center;
    border: 1px solid gray;
    border-radius: 16px;
    padding: 16px;
    margin: 0 16px 0 16px;
  }

  button {
    border: 1px solid gray;
    border-radius: 16px;
    margin: 16px;
    padding: 16px;
    font-size: 30pt;
  }
}
