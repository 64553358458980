.terms {
  height: 100%;
  overflow-y: scroll;
  padding: 8px;

  .back {
    display: block;
    font-weight: bold;
    font-size: 2em;
    margin: 0.2em;
  }

  h3 {
    font-size: 1.4em;
  }
  p {
    margin: 1.2em 0;
  }

  dt {
    font-weight: bold;
    margin-top: 1.4em;
  }

  dd {
    margin: 0.2em 0;
  }

  li {
    margin: 1.5em;
  }
}
