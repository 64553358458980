.all_entries {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-inline: 1rem;
  .item-entry {
    overflow: auto;
    background-color: #eeeeee;
    cursor: pointer;
  }
}
