$close-size: 2rem;

#close {
  position: absolute;
  top: 0.2rem;
  right: 0.25rem;
  z-index: 1;
}

#logout {
  position: absolute;
  top: 10px;
  right: 8px;
}
