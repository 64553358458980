.admin_root {
  position: relative;
  background-color: lightblue;

  .map {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
}
