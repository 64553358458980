* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
#root {
  height: 100%;
}

body {
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  overflow: hidden;
}

#bottom-ui {
  color: black;
  position: absolute;
  bottom: 32px;
  right: 2%;
  width: 96%;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  transition:
    opacity 0.3s,
    height 0.3s;
  opacity: 1;
  z-index: 1050;
}

h1,
h2,
h3,
h4 {
  color: #333f48;
}

p,
span,
div {
  color: #6f6f6f;
}

.form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  overflow: auto;
  max-height: 80%;
}

.login-image {
  padding: 5px;
  height: 125px;
}

.flexbox-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
